"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _Avatar = _interopRequireDefault(require("@material-ui/core/Avatar"));

var _styles = _interopRequireDefault(require("./styles"));

var _colors = require("./styles/colors");

var _random = _interopRequireDefault(require("../utils/random"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var Avatar = function Avatar(props) {
  var alt = props.alt,
      className = props.className,
      colored = props.colored,
      imgProps = props.imgProps,
      sizes = props.imgSizes,
      name = props.name,
      size = props.size,
      src = props.src,
      srcSet = props.srcSet,
      children = props.children;
  var propsToImg = {
    alt: alt,
    imgProps: imgProps,
    sizes: sizes,
    src: src,
    srcSet: srcSet
  };
  var classes = (0, _styles["default"])();

  if (src || srcSet) {
    return _react["default"].createElement(_Avatar["default"], _extends({}, propsToImg, {
      className: (0, _classnames["default"])(className, classes[size]),
      size: size
    }));
  }

  var initials = name || children;

  if (typeof initials === 'string') {
    initials = initials ? initials.split(' ').filter(function (c) {
      return c;
    }).map(function (c) {
      return c[0].toUpperCase();
    }).splice(0, 2).join('') : '';
  }

  var classesColor = colored ? classes[(0, _random["default"])(Object.keys(_colors.Colors))] : classes.defaultColor;
  return _react["default"].createElement(_Avatar["default"], {
    className: (0, _classnames["default"])(className, classesColor, classes[size]),
    size: size
  }, initials);
};

Avatar.propTypes = {
  className: _propTypes["default"].string,

  /**
   * Used in combination with `src` or `srcSet` to provide an alt attribute
   * for the renderer `img` element.
   */
  alt: _propTypes["default"].string,

  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api)
   */
  classes: _propTypes["default"].object,
  children: _propTypes["default"].node,

  /**
   * Used in combination with `name` to provide a color background.
   * If true, the background gets a random color,
   * otherwise gets a default color.
   */
  colored: _propTypes["default"].bool,

  /**
   * Attributes applied to the `img` element if the component is used to display an image.
   */
  imgProps: _propTypes["default"].object,

  /**
   * The `sizes` attribute for the `img` element.
   */
  imgSizes: _propTypes["default"].string,

  /**
   * Name to extract the initials and show them as the avatar content.
   */
  name: _propTypes["default"].string,

  /**
   * The `size` of the avatar.
   */
  size: _propTypes["default"].oneOf(['small', 'default', 'big']),

  /**
   * The `src` attribute for the `img` element.
   */
  src: _propTypes["default"].string,

  /**
   * The `srcSet` attribute for the `img` element.
   */
  srcSet: _propTypes["default"].string
};
Avatar.defaultProps = {
  alt: '',
  classes: {},
  className: null,
  children: null,
  colored: false,
  imgProps: {},
  imgSizes: '',
  name: '',
  size: 'default',
  src: '',
  srcSet: ''
};
var _default = Avatar;
exports["default"] = _default;