"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Grid = _interopRequireDefault(require("@material-ui/core/Grid"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var Grid = function Grid(props) {
  var children = props.children,
      item = props.item,
      alignContent = props.alignContent,
      alignItems = props.alignItems,
      container = props.container,
      justify = props.justify,
      classes = props.classes,
      direction = props.direction,
      wrap = props.wrap,
      lg = props.lg,
      md = props.md,
      sm = props.sm,
      xs = props.xs,
      spacing = props.spacing,
      className = props.className;
  var passThroughProps = {
    item: item,
    container: container,
    classes: classes,
    className: className
  };

  if (container) {
    passThroughProps = _objectSpread({}, passThroughProps, {
      spacing: spacing,
      wrap: wrap,
      justify: justify,
      alignContent: alignContent,
      alignItems: alignItems,
      direction: direction
    });
  } else if (item) {
    passThroughProps = _objectSpread({}, passThroughProps, {
      lg: lg,
      md: md,
      sm: sm,
      xs: xs
    });
  }

  return _react["default"].createElement(_Grid["default"], passThroughProps, children);
};

Grid.propTypes = {
  /**
   * Defines the `align-content` style property. It's applied for all screen sizes.
   */
  alignContent: _propTypes["default"].oneOf(['stretch', 'center', 'flex-start', 'flex-end', 'space-between', 'space-around']),

  /**
   * Defines the `align-items` style property. It's applied for all screen sizes.
   */
  alignItems: _propTypes["default"].oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),

  /**
   * The content of the component
   */
  children: _propTypes["default"].node.isRequired,

  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api)
   */
  classes: _propTypes["default"].object,

  /**
   * If `true`, the component will have the `flex container` behavior.
   * You should be wrapping `items` with a `container`.
   */
  container: _propTypes["default"].bool,
  item: _propTypes["default"].bool,
  className: _propTypes["default"].string,

  /**
   * Defines the `flex-direction` style property. It is applied for all screen sizes.
   */
  direction: _propTypes["default"].oneOf(['row', 'row-reverse', 'column', 'column-reverse']),

  /**
   * Defines the `justify-content` style property. It is applied for all screen sizes.
   */
  justify: _propTypes["default"].oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']),

  /**
   * Defines the `flex-wrap` style property. It's applied for all screen sizes.
   */
  wrap: _propTypes["default"].oneOf(['nowrap', 'wrap', 'wrap-reverse']),

  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `lg` breakpoint and wider screens if not overridden.
   */
  lg: _propTypes["default"].oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),

  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `md` breakpoint and wider screens if not overridden.
   */
  md: _propTypes["default"].oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),

  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `sm` breakpoint and wider screens if not overridden.
   */
  sm: _propTypes["default"].oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xs: _propTypes["default"].oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  spacing: _propTypes["default"].oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
};
Grid.defaultProps = {
  alignContent: 'stretch',
  alignItems: 'stretch',
  classes: {},
  className: null,
  container: false,
  justify: 'flex-start',
  direction: 'row',
  wrap: 'wrap',
  lg: false,
  md: false,
  sm: false,
  xs: false,
  spacing: 0,
  item: false
};
var _default = Grid;
exports["default"] = _default;