"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _clsx = _interopRequireDefault(require("clsx"));

var _colors = require("@material-ui/core/colors");

var _Snackbar = _interopRequireDefault(require("@material-ui/core/Snackbar"));

var _SnackbarContent = _interopRequireDefault(require("@material-ui/core/SnackbarContent"));

var _styles = require("@material-ui/core/styles");

var _IconButton = _interopRequireDefault(require("../IconButton"));

var _Icon = _interopRequireDefault(require("../Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var useStyles1 = (0, _styles.makeStyles)(function (theme) {
  return {
    checkCircle: {
      backgroundColor: _colors.green[600]
    },
    error: {
      backgroundColor: theme.palette.error.dark
    },
    info: {
      backgroundColor: theme.palette.primary.main
    },
    warning: {
      backgroundColor: _colors.amber[700]
    },
    icon: {
      fontSize: 20
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(0.6)
    },
    message: {
      display: 'flex',
      alignItems: 'center'
    }
  };
});

var SnackbarContentWrapper = function SnackbarContentWrapper(_ref) {
  var className = _ref.className,
      message = _ref.message,
      onClose = _ref.onClose,
      variant = _ref.variant,
      other = _objectWithoutProperties(_ref, ["className", "message", "onClose", "variant"]);

  var classes = useStyles1();
  return _react["default"].createElement(_SnackbarContent["default"], _extends({
    className: (0, _clsx["default"])(classes[variant], className),
    "aria-describedby": "client-snackbar",
    message: _react["default"].createElement("span", {
      id: "client-snackbar",
      className: classes.message
    }, _react["default"].createElement("div", {
      className: classes.iconVariant
    }, _react["default"].createElement(_Icon["default"], {
      fontSize: "default"
    }, variant)), message),
    action: [_react["default"].createElement(_IconButton["default"], {
      key: "close",
      "aria-label": "close",
      color: "inherit",
      onClick: onClose
    }, _react["default"].createElement(_Icon["default"], {
      fontSize: "small"
    }, "close"))]
  }, other));
};

SnackbarContentWrapper.propTypes = {
  className: _propTypes["default"].string,
  message: _propTypes["default"].string,
  onClose: _propTypes["default"].func,
  variant: _propTypes["default"].oneOf(['error', 'info', 'checkCircle', 'warning']).isRequired
};
SnackbarContentWrapper.defaultProps = {
  className: {},
  message: '',
  onClose: function onClose() {}
};
var useStyles2 = (0, _styles.makeStyles)(function (theme) {
  return {
    margin: {
      margin: theme.spacing(1)
    }
  };
});

var CustomizedSnackbars = function CustomizedSnackbars(_ref2) {
  var message = _ref2.message,
      variant = _ref2.variant,
      open = _ref2.open,
      handleClose = _ref2.handleClose;
  var classes = useStyles2();
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_Snackbar["default"], {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    open: open,
    autoHideDuration: 3000,
    onClose: handleClose
  }, _react["default"].createElement(SnackbarContentWrapper, {
    onClose: handleClose,
    variant: variant,
    className: classes.margin,
    message: message
  })));
};

CustomizedSnackbars.propTypes = {
  /**
   * The message to display.
   */
  message: _propTypes["default"].string,

  /**
   * Callback fired when the component requests to be closed.
   */
  handleClose: _propTypes["default"].func,

  /**
   * Variant of the type of message.
   */
  variant: _propTypes["default"].oneOf(['error', 'info', 'checkCircle', 'warning']).isRequired,

  /**
   * If `true`, Snackbar is open.
   */
  open: _propTypes["default"].bool
};
CustomizedSnackbars.defaultProps = {
  message: '',
  handleClose: function handleClose() {},
  open: false
};
var _default = CustomizedSnackbars;
exports["default"] = _default;