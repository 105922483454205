import React from 'react'
import IconButton from 'thieme-ui/core/IconButton'
import Icon from 'thieme-ui/core/Icon'
import Confirmation from 'thieme-ui/core/Confirmation'
import { useMutation } from 'thieme-auth/core/Graph'
import { deleteCoachMutation, coachQuery } from '../graphql'

const DeleteCoachButton = ({ coach: { id, name } }) => {
  const [deleteCoach] = useMutation(deleteCoachMutation, {
    variables: { id },
    update: store => {
      const data = store.readQuery({
        query: coachQuery,
      })

      data.coaches = data.coaches.filter(coach => coach.id !== id)
      store.writeQuery({ query: coachQuery, data })
    },
    optimisticResponse: {
      deleteCoach: {
        deleteCoach: {
          id,
        },
        __typename: 'Coach',
      },
    },
  })

  return (
    <Confirmation
      title="Coach löschen?"
      question={`Soll der Coach ${name} wirklich gelöscht werden?`}
    >
      <IconButton onClick={deleteCoach}>
        <Icon>delete</Icon>
      </IconButton>
    </Confirmation>
  )
}

export default DeleteCoachButton
