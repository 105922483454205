import React, { useContext, useState } from 'react'
import AppBar from 'thieme-ui/core/AppBar'
import { useQuery } from 'thieme-auth/core/Graph'
import { AuthenticationContext } from 'thieme-auth/core/Authenticator'
import SimpleTable from 'thieme-ui/core/SimpleTable'
import PagePreloader from 'thieme-ui/core/PagePreloader'
import SearchField from 'thieme-ui/core/SearchField'
import { coachQuery } from '../graphql'
import CoachDialog from './CoachDialog'
import CoachTableRow from './CoachTableRow'
import { makeStyles } from 'thieme-ui/core/utils/makeStyles'

const useStyles = makeStyles({
  chip: {
    marginRight: 10,
  },
})

const CoachList = () => {
  const { loading, data } = useQuery(coachQuery)
  const { signOut } = useContext(AuthenticationContext)
  const [searchValue, setSearchValue] = useState('')
  const [coach, setCoach] = useState(null)
  const classes = useStyles()

  if (loading && data === undefined) {
    return <PagePreloader />
  }

  const { coaches = [], currentUser = {} } = data
  const sortedCoaches = coaches.sort((a, b) => (a.name > b.name ? 1 : -1))

  const tableValues = sortedCoaches
    .filter(
      coach =>
        searchValue === '' ||
        JSON.stringify(coach)
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
    )
    .map(coach => CoachTableRow({ coach, setCoach, classes }))

  let timeout = null

  return (
    <>
      <AppBar
        title={`${tableValues.length} Coaches`}
        position="static"
        showMenuIcon={false}
        logoImage="https://pimage.sport-thieme.de/icon64/app-icon-coaching"
        username={currentUser.firstname}
        avatarImage={`https://pim.sport-thieme.com/userimage/${currentUser.id}`}
        signOut={signOut}
      />
      <div style={{ padding: 20 }}>
        <SearchField
          value={searchValue}
          onChange={value => {
            if (timeout) {
              clearTimeout(timeout)
            }
            timeout = setTimeout(() => {
              setSearchValue(value)
            }, 200)
          }}
        />
        {loading && <PagePreloader fillContainer />}
        {loading === false && (
          <SimpleTable headerData={['', 'Name', 'Homepage', 'Gebiet', '']} bodyData={tableValues} />
        )}
      </div>
      {coach !== null && (
        <CoachDialog
          coach={coach}
          onCancel={() => {
            setCoach(null)
          }}
          onSave={() => {
            setCoach(null)
          }}
        />
      )}
    </>
  )
}

export default CoachList
