"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var defaults = {
  palette: {
    primary: {
      main: '#1d78c3',
      dark: '#004884'
    },
    secondary: {
      main: '#de2525',
      dark: '#ff0000'
    }
  },
  shape: {
    borderColor: 'rgba(0, 0, 0, 0.23)'
  },
  overrides: {},
  typography: {
    useNextVariants: true
  }
};
var _default = defaults;
exports["default"] = _default;