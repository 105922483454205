"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _InputBase = _interopRequireDefault(require("@material-ui/core/InputBase"));

var _Search = _interopRequireDefault(require("@material-ui/icons/Search"));

var _styledSearchField = _interopRequireDefault(require("./styles/styledSearchField"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var SearchField = function SearchField(_ref) {
  var onChange = _ref.onChange,
      placeholder = _ref.placeholder,
      disabled = _ref.disabled,
      autoFocus = _ref.autoFocus,
      value = _ref.value,
      className = _ref.className;

  var _useState = (0, _react.useState)(value),
      _useState2 = _slicedToArray(_useState, 2),
      input = _useState2[0],
      setInput = _useState2[1];

  var classes = (0, _styledSearchField["default"])();

  var handleChange = function handleChange(event) {
    var fieldValue = event.target.value;
    setInput(fieldValue);
    onChange(fieldValue);
  };

  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])(className, classes.search)
  }, _react["default"].createElement("div", {
    className: classes.searchIcon
  }, _react["default"].createElement(_Search["default"], null)), _react["default"].createElement(_InputBase["default"], {
    autoFocus: autoFocus,
    placeholder: placeholder,
    classes: {
      root: classes.inputRoot,
      input: classes.inputInput
    },
    value: input,
    onChange: handleChange,
    disabled: disabled
  }));
};

SearchField.propTypes = {
  className: _propTypes["default"].string,

  /**
   * The input value, required for a controlled component.
   */
  value: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number, _propTypes["default"].bool, _propTypes["default"].object]),

  /**
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value`.
   */
  onChange: _propTypes["default"].func.isRequired,

  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder: _propTypes["default"].string,

  /**
   * If `true`, the input will be disabled.
   */
  disabled: _propTypes["default"].bool,
  autoFocus: _propTypes["default"].bool
};
SearchField.defaultProps = {
  className: null,
  value: '',
  placeholder: 'Suchen...',
  disabled: false,
  autoFocus: false
};
var _default = SearchField;
exports["default"] = _default;