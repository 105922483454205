"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _AppBar = _interopRequireDefault(require("@material-ui/core/AppBar"));

var _core = require("@material-ui/core");

var _classnames = _interopRequireDefault(require("classnames"));

var _Menu = _interopRequireDefault(require("@material-ui/icons/Menu"));

var _SearchField = _interopRequireDefault(require("../SearchField"));

var _Button = _interopRequireDefault(require("../Button"));

var _Menu2 = _interopRequireDefault(require("./components/Menu"));

var _Avatar = _interopRequireDefault(require("../Avatar"));

var _styles = _interopRequireDefault(require("./styles"));

var _Typography = require("../Typography");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var AppBar = function AppBar(props) {
  var handleDrawerToggle = props.handleDrawerToggle,
      open = props.open,
      position = props.position,
      title = props.title,
      username = props.username,
      avatarImage = props.avatarImage,
      logoImage = props.logoImage,
      showMenuIcon = props.showMenuIcon,
      signOut = props.signOut,
      search = props.search,
      userClasses = props.classes;
  var classes = (0, _styles["default"])();

  var _useState = (0, _react.useState)(),
      _useState2 = _slicedToArray(_useState, 2),
      anchorEl = _useState2[0],
      setAnchorEl = _useState2[1];

  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])(classes.root, userClasses.root)
  }, _react["default"].createElement(_core.CssBaseline, null), _react["default"].createElement(_AppBar["default"], {
    position: position,
    className: classes.appBar
  }, _react["default"].createElement(_core.Toolbar, {
    disableGutters: !open
  }, showMenuIcon && _react["default"].createElement(_core.IconButton, {
    onClick: handleDrawerToggle,
    color: "inherit",
    className: (0, _classnames["default"])(classes.menuButton, _defineProperty({}, classes.hide, open))
  }, _react["default"].createElement(_Menu["default"], null)), logoImage !== null && _react["default"].createElement(_Avatar["default"], {
    alt: "LOGO",
    src: logoImage,
    className: classes.logo
  }), _react["default"].createElement(_Typography.H6, {
    component: "span"
  }, title), _react["default"].createElement("div", {
    className: classes.grow
  }), search && _react["default"].createElement(_SearchField["default"], {
    className: (0, _classnames["default"])(classes.searchField, userClasses.searchField),
    autoFocus: search.autoFocus,
    value: search.value,
    onChange: search.onChange
  }), _react["default"].createElement("div", null, avatarImage !== null && _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_core.IconButton, {
    size: "small",
    onClick: function onClick(e) {
      setAnchorEl(e.currentTarget);
    }
  }, _react["default"].createElement(_Avatar["default"], {
    alt: username,
    src: avatarImage
  })), _react["default"].createElement(_Menu2["default"], {
    onClose: function onClose() {
      setAnchorEl(null);
    },
    username: username,
    signOut: signOut,
    anchorEl: anchorEl
  })), avatarImage === null && signOut !== null && _react["default"].createElement(_Button["default"], {
    variant: "text",
    color: "inherit",
    onClick: signOut
  }, "Logout")))));
};

AppBar.propTypes = {
  /**
   * The handler function for the Drawer component
   */
  handleDrawerToggle: _propTypes["default"].func,

  /**
   * username for presenting the logged in user
   */
  username: _propTypes["default"].string,
  avatarImage: _propTypes["default"].string,
  search: _propTypes["default"].shape({
    value: _propTypes["default"].string,
    onChange: _propTypes["default"].func,
    autoFocus: _propTypes["default"].bool
  }),
  showMenuIcon: _propTypes["default"].bool,
  logoImage: _propTypes["default"].string,

  /**
   * If `true` Toolbar component opens
   */
  open: _propTypes["default"].bool,

  /**
   * The positioning type
   */
  position: _propTypes["default"].oneOf(['fixed', 'absolute', 'relative', 'static', 'sticky']),

  /**
   * If `true` Toolbar component opens
   */
  title: _propTypes["default"].string,
  classes: _propTypes["default"].object,
  signOut: _propTypes["default"].func
};
AppBar.defaultProps = {
  avatarImage: null,
  handleDrawerToggle: Function.prototype,
  open: false,
  logoImage: null,
  position: 'fixed',
  search: null,
  showMenuIcon: false,
  signOut: null,
  title: '',
  classes: {},
  username: null
};
var _default = AppBar;
exports["default"] = _default;