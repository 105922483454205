"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactHotkeys = require("react-hotkeys");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DarkModeHotkey = function DarkModeHotkey(_ref) {
  var setDark = _ref.setDark;
  return _react["default"].createElement(_reactHotkeys.GlobalHotKeys, {
    keyMap: {
      TOGGLE_DARK: 'ctrl+shift+d'
    },
    handlers: {
      TOGGLE_DARK: function TOGGLE_DARK() {
        return setDark();
      }
    }
  });
};

DarkModeHotkey.propTypes = {
  setDark: _propTypes["default"].func.isRequired
};
var _default = DarkModeHotkey;
exports["default"] = _default;