import { gql } from 'thieme-auth/core/Graph'

export const coachQuery = gql`
  query coachAppList {
    coaches: listCoaches {
      id
      name
      email
      homepage
      company
      street
      city
      focus
    }
    currentUser {
      id
      email
      firstname
      lastname
      roles
    }
  }
`
export const coachUpdateMutation = gql`
  mutation coachAppUpdate($input: CoachInput) {
    updateCoach(input: $input) {
      id
      name
      email
      homepage
      company
      street
      city
      focus
    }
  }
`

export const deleteCoachMutation = gql`
  mutation deleteCoach($id: Int!) {
    deleteCoach(id: $id)
  }
`
