"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styles = require("@material-ui/core/styles");

var _ThemeDefaults = _interopRequireDefault(require("./ThemeDefaults"));

var _DarkThemeDefaults = _interopRequireDefault(require("./DarkThemeDefaults"));

var _JSSProvider = _interopRequireDefault(require("./JSSProvider"));

var _ThemeContext = _interopRequireDefault(require("./ThemeContext"));

var _DarkModeHotkey = _interopRequireDefault(require("./DarkModeHotkey"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var SPORT_THIEME_DARK_MODE_KEY = 'SPORT_THIEME_DARK_MODE';

var ThemeProvider = function ThemeProvider(props) {
  var children = props.children,
      theme = props.theme,
      dark = props.dark;

  var _useState = (0, _react.useState)(dark || localStorage.getItem(SPORT_THIEME_DARK_MODE_KEY) === 'true'),
      _useState2 = _slicedToArray(_useState, 2),
      darkState = _useState2[0],
      setDarkState = _useState2[1];

  var computedDark = darkState;

  var toggleDark = function toggleDark() {
    computedDark = !computedDark;
    setDarkState(computedDark);
    localStorage.setItem(SPORT_THIEME_DARK_MODE_KEY, computedDark);
  };

  var themeObject = computedDark ? Object.assign(_objectSpread({}, _DarkThemeDefaults["default"]), theme) : Object.assign(_objectSpread({}, _ThemeDefaults["default"]), theme);
  var muiTheme = (0, _styles.createMuiTheme)(themeObject);
  return _react["default"].createElement(_JSSProvider["default"], null, _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_DarkModeHotkey["default"], {
    setDark: toggleDark
  }), _react["default"].createElement(_ThemeContext["default"].Provider, {
    value: {
      darkState: [computedDark, setDarkState]
    }
  }, _react["default"].createElement(_styles.MuiThemeProvider, {
    theme: muiTheme
  }, children))));
};

ThemeProvider.propTypes = {
  /**
   * You can wrap a node.
   */
  children: _propTypes["default"].node.isRequired,

  /**
   * Change the theme mode to dark.
   */
  dark: _propTypes["default"].bool,

  /**
   * A theme object.
   */
  theme: _propTypes["default"].object
};
ThemeProvider.defaultProps = {
  dark: false,
  theme: {}
};
var _default = ThemeProvider;
exports["default"] = _default;