"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Chip = _interopRequireDefault(require("@material-ui/core/Chip"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Chip = function Chip(_ref) {
  var classes = _ref.classes,
      avatar = _ref.avatar,
      clickable = _ref.clickable,
      color = _ref.color,
      component = _ref.component,
      deleteIcon = _ref.deleteIcon,
      icon = _ref.icon,
      label = _ref.label,
      onDelete = _ref.onDelete,
      size = _ref.size,
      variant = _ref.variant,
      className = _ref.className;
  return _react["default"].createElement(_Chip["default"], {
    classes: classes,
    className: className,
    avatar: avatar,
    clickable: clickable,
    color: color,
    component: component,
    deleteIcon: deleteIcon,
    icon: icon,
    label: label,
    onDelete: onDelete,
    size: size,
    variant: variant
  });
};

Chip.propTypes = {
  classes: _propTypes["default"].object,
  className: _propTypes["default"].string,

  /**
   * Avatar element
   */
  avatar: _propTypes["default"].element,

  /**
   * If true, the chip will appear clickable, and will raise when pressed,
   * even if the onClick prop is not defined.
   * If false, the chip will not be clickable, even if onClick prop is defined.
   * This can be used, for example, along with the component prop to
   * indicate an anchor Chip is clickable.
   */
  clickable: _propTypes["default"].bool,
  color: _propTypes["default"].oneOf(['default', 'primary', 'secondary']),
  component: _propTypes["default"].elementType,
  deleteIcon: _propTypes["default"].element,
  icon: _propTypes["default"].element,
  label: _propTypes["default"].node,
  onDelete: _propTypes["default"].func,
  size: _propTypes["default"].oneOf(['small', 'medium']),
  variant: _propTypes["default"].oneOf(['default', 'outlined'])
};
Chip.defaultProps = {
  avatar: null,
  classes: {},
  className: null,
  clickable: null,
  color: 'default',
  component: 'div',
  deleteIcon: null,
  icon: null,
  label: null,
  onDelete: null,
  size: 'medium',
  variant: 'default'
};
var _default = Chip;
exports["default"] = _default;