"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Paper = _interopRequireDefault(require("@material-ui/core/Paper"));

var _styles = require("@material-ui/core/styles");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var useStyles = (0, _styles.makeStyles)(function (theme) {
  return {
    root: {
      padding: theme.spacing(3, 2)
    }
  };
});

var Paper = function Paper(_ref) {
  var children = _ref.children,
      elevation = _ref.elevation,
      square = _ref.square,
      classes = _ref.classes,
      className = _ref.className,
      padding = _ref.padding;
  var computedClasses = {};

  if (padding) {
    computedClasses = Object.assign(computedClasses, useStyles());
  }

  computedClasses = Object.assign(computedClasses, classes);
  return _react["default"].createElement(_Paper["default"], {
    className: className,
    classes: computedClasses,
    elevation: elevation,
    square: square
  }, children);
};

Paper.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api).
   */
  classes: _propTypes["default"].object,
  className: _propTypes["default"].string,

  /**
   * The content of the component.
   */
  children: _propTypes["default"].node.isRequired,

  /**
   * Shadow depth, corresponds to `dp` in the spec.
   * It's accepting values between 0 and 24 inclusive.
   */
  elevation: _propTypes["default"].number,

  /**
   * If `true`, rounded corners are disabled.
   */
  square: _propTypes["default"].bool,

  /**
   * If `true, it adds a default padding to the paper
   */
  padding: _propTypes["default"].bool
};
Paper.defaultProps = {
  className: null,
  classes: {},
  elevation: 2,
  padding: false,
  square: false
};
var _default = Paper;
exports["default"] = _default;