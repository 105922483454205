"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DialogContentText = _interopRequireDefault(require("@material-ui/core/DialogContentText"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DialogContentText = function DialogContentText(_ref) {
  var children = _ref.children,
      classes = _ref.classes;
  return _react["default"].createElement(_DialogContentText["default"], {
    classes: classes
  }, children);
};

DialogContentText.propTypes = {
  children: _propTypes["default"].node.isRequired,
  classes: _propTypes["default"].object
};
DialogContentText.defaultProps = {
  classes: {}
};
var _default = DialogContentText;
exports["default"] = _default;