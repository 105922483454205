"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _IconButton = _interopRequireDefault(require("@material-ui/core/IconButton"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var IconButton = function IconButton(props) {
  var children = props.children,
      classes = props.classes,
      color = props.color,
      disabled = props.disabled,
      onClick = props.onClick,
      edge = props.edge,
      size = props.size;
  return _react["default"].createElement(_IconButton["default"], {
    classes: classes,
    color: color,
    edge: edge,
    disabled: disabled,
    onClick: onClick,
    size: size
  }, children);
};

IconButton.propTypes = {
  size: _propTypes["default"].oneOf(['small', 'medium']),

  /**
   * If given, uses a negative margin to counteract the padding on
   *  one side (this is often helpful for aligning the left or right
   *  side of the icon with content above or below, without ruining
   *  the border size and shape).
   */
  edge: _propTypes["default"].oneOf(['start', 'end', false]),

  /**
   * The content of the button.
   */
  children: _propTypes["default"].node.isRequired,

  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api)
   */
  classes: _propTypes["default"].object,

  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: _propTypes["default"].oneOf(['default', 'inherit', 'primary', 'secondary']),

  /**
   * If `true`, the button will be disabled.
   */
  disabled: _propTypes["default"].bool,

  /**
   * The handler for the onClick event
   */
  onClick: _propTypes["default"].func
};
IconButton.defaultProps = {
  classes: {},
  color: 'primary',
  disabled: false,
  onClick: Function.prototype,
  edge: false,
  size: 'medium'
};
var _default = IconButton;
exports["default"] = _default;