"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Link = _interopRequireDefault(require("@material-ui/core/Link"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Link = function Link(props) {
  var component = props.component,
      children = props.children,
      classes = props.classes,
      color = props.color,
      underline = props.underline,
      to = props.to,
      target = props.target;
  var href = props.href;

  if (component === 'a' && to) {
    href = to;
  }

  var passThroughProps = {
    component: component,
    children: children,
    classes: classes,
    color: color,
    underline: underline,
    to: to,
    href: href,
    target: target
  };
  return _react["default"].createElement(_Link["default"], passThroughProps, children);
};

Link.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api)
   */
  classes: _propTypes["default"].object,

  /**
   * The content of the link.
   */
  children: _propTypes["default"].node,

  /**
   * The color of the link
   */
  color: _propTypes["default"].oneOf(['error', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary']),

  /**
   * The component used for the root node. Either a string to use a DOM element or a component.
   */
  component: _propTypes["default"].any,

  /**
   * Controls when the link should have an underline.
   */
  underline: _propTypes["default"].oneOf(['none', 'hover', 'always']),

  /**
   * Params of the path
   */
  to: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number]),
  href: _propTypes["default"].string,
  target: _propTypes["default"].string
};
Link.defaultProps = {
  color: 'primary',
  component: 'a',
  underline: 'hover',
  classes: {},
  children: {},
  to: null,
  href: null,
  target: null
};
var _default = Link;
exports["default"] = _default;