"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _SimpleDialogAction = _interopRequireDefault(require("./SimpleDialogAction"));

var _Dialog = require("../Dialog");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var SimpleDialog = function SimpleDialog(_ref) {
  var open = _ref.open,
      title = _ref.title,
      children = _ref.children,
      text = _ref.text,
      onClose = _ref.onClose,
      actions = _ref.actions,
      fullScreen = _ref.fullScreen,
      maxWidth = _ref.maxWidth,
      fullWidth = _ref.fullWidth;

  var _useState = (0, _react.useState)(true),
      _useState2 = _slicedToArray(_useState, 2),
      selfOpen = _useState2[0],
      setSelfOpen = _useState2[1];

  var openState = open;
  var onCloseProp = onClose;
  var actionProp = actions;

  if (open === null) {
    openState = selfOpen;

    onCloseProp = function onCloseProp() {
      setSelfOpen(false);
    };
  }

  if (actions.length === 0) {
    actionProp = [_react["default"].createElement(_SimpleDialogAction["default"], {
      title: "Ok",
      isDefault: true,
      onClick: onCloseProp,
      key: 0
    })];
  }

  return _react["default"].createElement(_Dialog.Dialog, {
    maxWidth: maxWidth,
    fullWidth: fullWidth,
    fullScreen: fullScreen,
    open: openState,
    onClose: onCloseProp
  }, title !== null && _react["default"].createElement(_Dialog.DialogTitle, null, title), (text !== null || children !== null) && _react["default"].createElement(_Dialog.DialogContent, null, text !== null && _react["default"].createElement(_Dialog.DialogContentText, null, text), children), _react["default"].createElement(_Dialog.DialogActions, null, actionProp));
};

SimpleDialog.propTypes = {
  /**
   * Title of the dialog. SimpleDialog then uses DialogTitle for you
   */
  title: _propTypes["default"].string,

  /**
   * Something to present. If you just present text, please use text property.
   */
  children: _propTypes["default"].node,

  /**
   * Will be used instead of children. SimpleDialog then use DialogContentText for you
   */
  text: _propTypes["default"].string,

  /**
   * If true, the Dialog is open.
   */
  open: _propTypes["default"].bool,

  /**
   * If true, the dialog will be full-screen
   */
  fullScreen: _propTypes["default"].bool,

  /**
   *  Callback fired when the component requests to be closed.
   */
  onClose: _propTypes["default"].func,

  /**
   * Please only present an array of SimpleDialogAction
   */
  actions: _propTypes["default"].arrayOf(_propTypes["default"].element),

  /**
   * Determine the max-width of the dialog. The dialog width grows with
   * the size of the screen. Set to false to disable
   */
  maxWidth: _propTypes["default"].oneOf([false, 'xs', 'sm', 'md', 'lg', 'xl']),

  /**
   * If true, the dialog stretches to maxWidth.
   */
  fullWidth: _propTypes["default"].bool
};
SimpleDialog.defaultProps = {
  actions: [],
  children: null,
  fullScreen: false,
  fullWidth: false,
  onClose: null,
  open: null,
  maxWidth: 'sm',
  text: null,
  title: null
};
var _default = SimpleDialog;
exports["default"] = _default;