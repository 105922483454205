"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Colors = exports.Default = void 0;

var _amber = _interopRequireDefault(require("@material-ui/core/colors/amber"));

var _blue = _interopRequireDefault(require("@material-ui/core/colors/blue"));

var _blueGrey = _interopRequireDefault(require("@material-ui/core/colors/blueGrey"));

var _brown = _interopRequireDefault(require("@material-ui/core/colors/brown"));

var _cyan = _interopRequireDefault(require("@material-ui/core/colors/cyan"));

var _deepOrange = _interopRequireDefault(require("@material-ui/core/colors/deepOrange"));

var _deepPurple = _interopRequireDefault(require("@material-ui/core/colors/deepPurple"));

var _green = _interopRequireDefault(require("@material-ui/core/colors/green"));

var _grey = _interopRequireDefault(require("@material-ui/core/colors/grey"));

var _indigo = _interopRequireDefault(require("@material-ui/core/colors/indigo"));

var _lightBlue = _interopRequireDefault(require("@material-ui/core/colors/lightBlue"));

var _lightGreen = _interopRequireDefault(require("@material-ui/core/colors/lightGreen"));

var _lime = _interopRequireDefault(require("@material-ui/core/colors/lime"));

var _orange = _interopRequireDefault(require("@material-ui/core/colors/orange"));

var _pink = _interopRequireDefault(require("@material-ui/core/colors/pink"));

var _purple = _interopRequireDefault(require("@material-ui/core/colors/purple"));

var _red = _interopRequireDefault(require("@material-ui/core/colors/red"));

var _teal = _interopRequireDefault(require("@material-ui/core/colors/teal"));

var _yellow = _interopRequireDefault(require("@material-ui/core/colors/yellow"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var COLOR_BLACK = '#000';
var COLOR_WHITE = '#fff';
var VALUE_SHADE = 500;
var Default = {
  backgroundColor: _grey["default"][VALUE_SHADE],
  color: COLOR_WHITE
};
exports.Default = Default;
var Colors = {
  amber: {
    backgroundColor: _amber["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  blue: {
    backgroundColor: _blue["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  blueGrey: {
    backgroundColor: _blueGrey["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  brown: {
    backgroundColor: _brown["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  cyan: {
    backgroundColor: _cyan["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  deepOrange: {
    backgroundColor: _deepOrange["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  deepPurple: {
    backgroundColor: _deepPurple["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  green: {
    backgroundColor: _green["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  indigo: {
    backgroundColor: _indigo["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  lightBlue: {
    backgroundColor: _lightBlue["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  lightGreen: {
    backgroundColor: _lightGreen["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  lime: {
    backgroundColor: _lime["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  orange: {
    backgroundColor: _orange["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  pink: {
    backgroundColor: _pink["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  purple: {
    backgroundColor: _purple["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  red: {
    backgroundColor: _red["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  teal: {
    backgroundColor: _teal["default"][VALUE_SHADE],
    color: COLOR_WHITE
  },
  yellow: {
    backgroundColor: _yellow["default"][VALUE_SHADE],
    color: COLOR_BLACK
  }
};
exports.Colors = Colors;