import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SimpleDialog, { SimpleDialogAction } from 'thieme-ui/core/SimpleDialog'
import TextField from 'thieme-ui/core/TextField'
import Grid from 'thieme-ui/core/Grid'
import Chip from 'thieme-ui/core/Chip'
import { useMutation } from 'thieme-auth/core/Graph'
import { makeStyles } from 'thieme-ui/core/utils/makeStyles'

import { coachUpdateMutation, coachQuery } from '../graphql/'

const useStyles = makeStyles(theme => ({
  chip: { margin: 10 },
  addTextFieldWrapper: { padding: 10 },
  paper: { marginTop: 30 },
  chipWrapper: {
    marginTop: 10,
    padding: 10,
    border: '1px solid #ccc',
    borderColor: theme.shape.borderColor,
    borderRadius: theme.shape.borderRadius,
  },
}))

const CoachDialog = ({ coach, onSave, onCancel }) => {
  const open = coach !== null

  const [editCoach, setCoach] = useState({ ...coach })
  const [addTag, setAddTag] = useState('')
  const classes = useStyles()

  const [updateCoach] = useMutation(coachUpdateMutation, {
    variables: { input: editCoach },
    update: (store, { data: updateCoach }) => {
      const data = store.readQuery({
        query: coachQuery,
      })
      const updatedCoach = updateCoach.updateCoach
      data.coaches = data.coaches.map(coach => {
        if (coach.id === updatedCoach.id) {
          return updatedCoach
        }
        return coach
      })

      store.writeQuery({ query: coachQuery, data })
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateCoach: {
        ...editCoach,
        __typename: 'Coach',
      },
    },
  })

  const saveAction = (
    <SimpleDialogAction
      key="Speichern"
      title="Speichern"
      onClick={() => {
        delete editCoach.__typename
        updateCoach()
        onSave(editCoach)
      }}
      isDefault
    />
  )

  const cancelAction = (
    <SimpleDialogAction
      key="Abbrechen"
      title="Abbrechen"
      onClick={() => {
        onCancel(editCoach)
      }}
    />
  )

  return (
    <SimpleDialog
      open={open}
      fullScreen
      title={editCoach.name}
      actions={[cancelAction, saveAction]}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            value={editCoach.name}
            onChange={e => {
              editCoach.name = e.target.value
              setCoach({ ...editCoach })
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Company"
            value={editCoach.company}
            onChange={e => {
              editCoach.company = e.target.value
              setCoach({ ...editCoach })
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Website"
            value={editCoach.homepage}
            onChange={e => {
              editCoach.homepage = e.target.value
              setCoach({ ...editCoach })
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label="Straße"
            value={editCoach.street}
            onChange={e => {
              editCoach.street = e.target.value
              setCoach({ ...editCoach })
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label="PLZ"
            value={editCoach.zip}
            onChange={e => {
              editCoach.zip = e.target.value
              setCoach({ ...editCoach })
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label="Stadt"
            value={editCoach.city}
            onChange={e => {
              editCoach.city = e.target.value
              setCoach({ ...editCoach })
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.chipWrapper}>
          {editCoach.focus.map(focus => (
            <Chip
              onDelete={() => {
                editCoach.focus = editCoach.focus.filter(f => f !== focus)
                setCoach({ ...editCoach })
              }}
              className={classes.chip}
              color="primary"
              key={focus}
              label={focus}
            />
          ))}
          <div className={classes.addTextFieldWrapper}>
            <TextField
              variant="standard"
              label="Hinzufügen"
              placeholder="Hinzufügen"
              value={addTag}
              onChange={e => {
                setAddTag(e.target.value)
              }}
              onEnter={e => {
                const value = e.target.value
                if (!editCoach.focus.includes(value)) {
                  editCoach.focus.push(value)

                  setAddTag('')
                  setCoach({ ...editCoach })
                }
              }}
            />
          </div>
        </div>
      </Grid>
    </SimpleDialog>
  )
}

CoachDialog.propTypes = {
  coach: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

CoachDialog.defaultProps = {
  coach: null,
}

export default CoachDialog
