"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DialogContent = _interopRequireDefault(require("@material-ui/core/DialogContent"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DialogContent = function DialogContent(_ref) {
  var children = _ref.children,
      classes = _ref.classes,
      dividers = _ref.dividers;
  return _react["default"].createElement(_DialogContent["default"], {
    classes: classes,
    dividers: dividers
  }, children);
};

DialogContent.propTypes = {
  children: _propTypes["default"].node.isRequired,
  classes: _propTypes["default"].object,
  dividers: _propTypes["default"].bool
};
DialogContent.defaultProps = {
  classes: {},
  dividers: false
};
var _default = DialogContent;
exports["default"] = _default;