"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _Slide = _interopRequireDefault(require("@material-ui/core/Slide"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var Transition = _react["default"].forwardRef(function (props, ref) {
  return _react["default"].createElement(_Slide["default"], _extends({
    direction: "up",
    ref: ref
  }, props));
});

var _default = Transition;
exports["default"] = _default;