"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styles = require("@material-ui/core/styles");

var _core = require("@material-ui/core");

var _Table = _interopRequireDefault(require("@material-ui/core/Table"));

var _styledTable = _interopRequireDefault(require("./styles/styledTable"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var CustomTableCell = (0, _styles.withStyles)(function (theme) {
  return {
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  };
})(_core.TableCell);

var getValues = function getValues(object) {
  return Object.entries(object).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return _react["default"].createElement(_core.TableCell, {
      key: key
    }, value);
  });
};

var SimpleTable = function SimpleTable(props) {
  var padding = props.padding,
      headerData = props.headerData,
      bodyData = props.bodyData,
      customed = props.customed;
  var classes = (0, _styledTable["default"])();
  return _react["default"].createElement(_core.Paper, {
    className: classes.root
  }, _react["default"].createElement(_Table["default"], {
    className: classes.table,
    padding: padding
  }, _react["default"].createElement(_core.TableHead, null, _react["default"].createElement(_core.TableRow, null, headerData.map(function (item, i) {
    var key = i + item;
    if (customed) return _react["default"].createElement(CustomTableCell, {
      key: key
    }, item);
    return _react["default"].createElement(_core.TableCell, {
      key: key
    }, item);
  }))), _react["default"].createElement(_core.TableBody, null, bodyData.map(function (row, i) {
    var key = "index".concat(i);
    return _react["default"].createElement(_core.TableRow, {
      key: key,
      hover: true,
      className: "".concat(customed ? classes.row : '')
    }, getValues(row));
  }))));
};

SimpleTable.propTypes = {
  /**
   * Allows TableCells to inherit padding of the Table.
   */
  padding: _propTypes["default"].oneOf(['default', 'checkbox', 'dense', 'none']),

  /**
   * Column names in header
   */
  headerData: _propTypes["default"].array.isRequired,

  /**
   * Data to be rendered in table
   */
  bodyData: _propTypes["default"].array.isRequired,

  /**
   * If `true`, the table gets customed
   */
  customed: _propTypes["default"].bool
};
SimpleTable.defaultProps = {
  padding: 'default',
  customed: false
};
var _default = SimpleTable;
exports["default"] = _default;