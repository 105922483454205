"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _SvgIcon = _interopRequireDefault(require("@material-ui/core/SvgIcon"));

var _Icon = _interopRequireDefault(require("@material-ui/core/Icon"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Icon = function Icon(props) {
  var FontIcon = props.name,
      icon = props.icon,
      name = props.name,
      children = props.children,
      size = props.size,
      fontSize = props.fontSize,
      viewBox = props.viewBox;

  var output = _react["default"].createElement(_Icon["default"], {
    fontSize: fontSize
  }, children);

  if (icon !== null) {
    output = _react["default"].createElement(_SvgIcon["default"], {
      width: size,
      height: size,
      viewBox: viewBox
    }, _react["default"].createElement("path", {
      d: icon
    }));
  } else if (name !== null) {
    output = _react["default"].createElement(FontIcon, {
      fontSize: fontSize
    });
  }

  return output;
};

Icon.propTypes = {
  /**
   * SVG icon props
   */
  icon: _propTypes["default"].string,

  /**
   * Font name props
   */
  name: _propTypes["default"].func,
  children: _propTypes["default"].node,
  fontSize: _propTypes["default"].oneOf(['inherit', 'default', 'small', 'large']),
  size: _propTypes["default"].number,
  viewBox: _propTypes["default"].string
};
Icon.defaultProps = {
  icon: null,
  name: null,
  children: null,
  size: 24,
  fontSize: 'default',
  viewBox: '0 0 1024 1024'
};
var _default = Icon;
exports["default"] = _default;