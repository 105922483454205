"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Button = _interopRequireDefault(require("../Button"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var SimpleDialogAction = function SimpleDialogAction(_ref) {
  var title = _ref.title,
      onClick = _ref.onClick,
      isDestructive = _ref.isDestructive,
      isDefault = _ref.isDefault;
  var variant = null;
  var color = 'primary';

  if (isDefault) {
    variant = 'contained';
    color = 'primary';
  }

  if (isDestructive) {
    color = 'secondary';
    variant = 'contained';
  }

  return _react["default"].createElement(_Button["default"], {
    variant: variant,
    color: color,
    onClick: onClick
  }, title);
};

SimpleDialogAction.propTypes = {
  title: _propTypes["default"].string,
  onClick: _propTypes["default"].func,
  isDestructive: _propTypes["default"].bool,
  isDefault: _propTypes["default"].bool
};
SimpleDialogAction.defaultProps = {
  title: null,
  onClick: null,
  isDestructive: false,
  isDefault: false
};
var _default = SimpleDialogAction;
exports["default"] = _default;