"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var variants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline', 'srOnly', 'inherit'];

var reducer = function reducer(functions, variant) {
  var result = functions;
  var name = variant.charAt(0).toUpperCase() + variant.slice(1);

  var component = function component(_ref) {
    var align = _ref.align,
        children = _ref.children,
        classes = _ref.classes,
        color = _ref.color,
        display = _ref.display,
        gutterBottom = _ref.gutterBottom,
        noWrap = _ref.noWrap,
        paragraph = _ref.paragraph,
        className = _ref.className;
    return _react["default"].createElement(_Typography["default"], _extends({
      variant: variant
    }, {
      align: align,
      classes: classes,
      color: color,
      display: display,
      gutterBottom: gutterBottom,
      noWrap: noWrap,
      paragraph: paragraph,
      className: className
    }), children);
  };

  component.propTypes = {
    /**
     * Set the text-align on the component.
     */
    align: _propTypes["default"].oneOf(['inherit', 'left', 'center', 'right', 'justify']),

    /**
     * The content of the component.
     */
    children: _propTypes["default"].node.isRequired,

    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css-api).
     */
    classes: _propTypes["default"].object,

    /**
     * The color of the component.
     * It supports those theme colors that make sense for this component.
     */
    color: _propTypes["default"].oneOf(['initial', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary', 'error']),

    /**
     * Controls the display type.
     */
    display: _propTypes["default"].oneOf(['initial', 'block', 'inline']),

    /**
     * If `true`, the text will have a bottom margin.
     */
    gutterBottom: _propTypes["default"].bool,

    /**
     * If `true`, the text will not wrap, but instead will truncate with an ellipsis.
     */
    noWrap: _propTypes["default"].bool,

    /**
     * If `true`, the text will have a bottom margin.
     */
    paragraph: _propTypes["default"].bool,

    /**
     * Override or extend the styles applied to the component.
     */
    className: _propTypes["default"].string
  };
  component.defaultProps = {
    align: 'inherit',
    color: 'initial',
    classes: {},
    className: null,
    display: 'initial',
    gutterBottom: false,
    noWrap: false,
    paragraph: false
  };
  result[name] = component;
  return result;
};

var _default = variants.reduce(reducer, {});

exports["default"] = _default;