"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _Menu = _interopRequireDefault(require("@material-ui/core/Menu"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _MenuItem = _interopRequireDefault(require("@material-ui/core/MenuItem"));

var _ListItem = _interopRequireDefault(require("@material-ui/core/ListItem"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Menu = function Menu(_ref) {
  var username = _ref.username,
      signOut = _ref.signOut,
      anchorEl = _ref.anchorEl,
      onClose = _ref.onClose;

  if (username === null || signOut === 'null') {
    return _react["default"].createElement(_react["default"].Fragment, null);
  }

  return _react["default"].createElement(_Menu["default"], {
    keepMounted: true,
    anchorEl: anchorEl,
    open: Boolean(anchorEl),
    onClose: onClose
  }, _react["default"].createElement(_ListItem["default"], null, username), _react["default"].createElement(_MenuItem["default"], {
    onClick: signOut
  }, "Abmelden"));
};

Menu.propTypes = {
  username: _propTypes["default"].string,
  signOut: _propTypes["default"].func,
  onClose: _propTypes["default"].func,
  anchorEl: _propTypes["default"].object
};
Menu.defaultProps = {
  username: null,
  signOut: null,
  anchorEl: null,
  onClose: null
};
var _default = Menu;
exports["default"] = _default;