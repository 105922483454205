"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AuthenticationContext", {
  enumerable: true,
  get: function get() {
    return _AuthenticatorContext["default"];
  }
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _Authenticator["default"];
  }
});
Object.defineProperty(exports, "signOut", {
  enumerable: true,
  get: function get() {
    return _Authenticator.signOut;
  }
});

var _AuthenticatorContext = _interopRequireDefault(require("./AuthenticatorContext"));

var _Authenticator = _interopRequireWildcard(require("./Authenticator"));

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}