"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Button = function Button(props) {
  var children = props.children,
      classes = props.classes,
      color = props.color,
      disabled = props.disabled,
      fullWidth = props.fullWidth,
      label = props.label,
      onClick = props.onClick,
      size = props.size,
      type = props.type,
      variant = props.variant;
  var content = label || children;
  return _react["default"].createElement(_Button["default"], {
    classes: classes,
    color: color,
    disabled: disabled,
    fullWidth: fullWidth,
    onClick: onClick,
    size: size,
    type: type,
    variant: variant
  }, content);
};

Button.propTypes = {
  /**
   * The text of the button.
   */
  children: _propTypes["default"].node,

  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api)
   */
  classes: _propTypes["default"].object,

  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: _propTypes["default"].oneOf(['default', 'inherit', 'primary', 'secondary']),

  /**
   * If `true`, the button will be disabled.
   */
  disabled: _propTypes["default"].bool,

  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth: _propTypes["default"].bool,

  /**
   * The content of the button.
   */
  label: _propTypes["default"].string,

  /**
   * The handler for the onClick event
   */
  onClick: _propTypes["default"].func,

  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  size: _propTypes["default"].oneOf(['small', 'medium', 'large']),

  /**
   * Type of the button
   */
  type: _propTypes["default"].oneOf(['button', 'submit', 'reset']),

  /**
   * The variant to use.
   */
  variant: _propTypes["default"].oneOf(['text', 'outlined', 'contained'])
};
Button.defaultProps = {
  children: '',
  classes: {},
  color: 'primary',
  disabled: false,
  fullWidth: false,
  label: '',
  onClick: Function.prototype,
  size: 'medium',
  type: 'button',
  variant: 'contained'
};
var _default = Button;
exports["default"] = _default;