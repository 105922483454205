import React from 'react'
import ReactDOM from 'react-dom'
import ThemeProvider from 'thieme-ui/core/ThemeProvider'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Authenticator from 'thieme-auth/core/Authenticator'
import GraphProvider from 'thieme-auth/core/Graph'

ReactDOM.render(
  <ThemeProvider>
    <Authenticator>
      <GraphProvider>
        <App />
      </GraphProvider>
    </Authenticator>
  </ThemeProvider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
