"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _JssProvider = _interopRequireDefault(require("react-jss/lib/JssProvider"));

var _jss = require("jss");

var _styles = require("@material-ui/styles");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var generateClassName = (0, _styles.createGenerateClassName)();
var jss = (0, _jss.create)((0, _styles.jssPreset)()); // We define a custom insertion point that JSS will look for injecting the styles in the DOM.

jss.options.insertionPoint = 'jss-insertion-point';

var JSSProvider = function JSSProvider(_ref) {
  var children = _ref.children;
  return _react["default"].createElement(_JssProvider["default"], {
    jss: jss,
    generateClassName: generateClassName
  }, children);
};

JSSProvider.propTypes = {
  children: _propTypes["default"].element.isRequired
};
var _default = JSSProvider;
exports["default"] = _default;