"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "styled", {
  enumerable: true,
  get: function get() {
    return _styles.styled;
  }
});
Object.defineProperty(exports, "useTheme", {
  enumerable: true,
  get: function get() {
    return _styles.useTheme;
  }
});
exports.makeStyles = void 0;

var _styles = require("@material-ui/core/styles");

var makeStyles = function makeStyles(styles) {
  return (0, _styles.makeStyles)(styles, {
    name: 'thieme-app'
  });
};

exports.makeStyles = makeStyles;