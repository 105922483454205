"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _TextField = _interopRequireDefault(require("@material-ui/core/TextField"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var TextField = function TextField(props) {
  var type = props.type,
      label = props.label,
      name = props.name,
      variant = props.variant,
      onChange = props.onChange,
      placeholder = props.placeholder,
      InputProps = props.InputProps,
      fullWidth = props.fullWidth,
      onEnter = props.onEnter,
      value = props.value,
      className = props.className;
  return _react["default"].createElement(_TextField["default"], {
    className: className,
    type: type,
    label: label,
    name: name,
    variant: variant,
    onChange: onChange,
    value: value || '',
    placerholder: placeholder,
    InputProps: InputProps,
    fullWidth: fullWidth,
    onKeyPress: function onKeyPress(event) {
      if (event.key === 'Enter' && onEnter !== null) {
        onEnter(event);
        event.preventDefault();
      }
    }
  });
};

TextField.propTypes = {
  /**
   * Classname for using jss
   */
  className: _propTypes["default"].string,

  /**
   * The label content
   */
  label: _propTypes["default"].node,

  /**
   * Name attribute of the `Input` element
   */
  name: _propTypes["default"].string,

  /**
   * Callback fired when the value changes.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value`
   */
  onChange: _propTypes["default"].func,
  onEnter: _propTypes["default"].func,

  /**
   * Type attribute of the `Input` element. It should be a valid `HTML5` input type.
   */
  type: _propTypes["default"].string,

  /**
   * The value of the `Input` element, required for a controlled component.
   */
  value: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number, _propTypes["default"].bool]),

  /**
   * The variant to use
   */
  variant: _propTypes["default"].oneOf(['standard', 'outlined', 'filled']),

  /**
   * The short hint displayed in the input before the user enters a value
   */
  placeholder: _propTypes["default"].string,

  /**
   * Properties applied to the `Input` element.
   */
  InputProps: _propTypes["default"].object,

  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth: _propTypes["default"].bool
};
TextField.defaultProps = {
  className: '',
  label: '',
  name: '',
  onChange: Function.prototype,
  onEnter: null,
  placeholder: '',
  type: 'text',
  value: '',
  variant: 'outlined',
  InputProps: {},
  fullWidth: true
};
var _default = TextField;
exports["default"] = _default;