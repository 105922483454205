import React from 'react'
import Link from 'thieme-ui/core/Link'
import Avatar from 'thieme-ui/core/Avatar'
import IconButton from 'thieme-ui/core/IconButton'
import Icon from 'thieme-ui/core/Icon'
import Chip from 'thieme-ui/core/Chip'
import DeleteCoachButton from './DeleteCoachButton'

const CoachTableRow = ({ coach, setCoach, classes }) => {
  const { name, homepage, focus } = coach
  return [
    <IconButton
      size="small"
      onClick={() => {
        setCoach(coach)
      }}
    >
      <Avatar colored={false} size="small" style={{ backgroundColor: 'red' }}>
        {name}
      </Avatar>
    </IconButton>,
    name,
    homepage !== null && (
      <Link href={`http://${homepage}`} target="_blank">
        {homepage}
      </Link>
    ),
    <>
      {focus.map(coachFocus => (
        <Chip
          size="small"
          color="primary"
          className={classes.chip}
          clickable={false}
          variant="outlined"
          label={coachFocus}
          key={coachFocus}
        />
      ))}
    </>,
    <>
      <IconButton
        onClick={() => {
          setCoach(coach)
        }}
      >
        <Icon fontSize="small">edit</Icon>
      </IconButton>
      <DeleteCoachButton coach={coach} />
    </>,
  ]
}

export default CoachTableRow
