"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DialogTitle = _interopRequireDefault(require("@material-ui/core/DialogTitle"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DialogTitle = function DialogTitle(_ref) {
  var children = _ref.children,
      classes = _ref.classes,
      disableTypography = _ref.disableTypography;
  return _react["default"].createElement(_DialogTitle["default"], {
    classes: classes,
    disableTypography: disableTypography
  }, children);
};

DialogTitle.propTypes = {
  children: _propTypes["default"].node.isRequired,
  classes: _propTypes["default"].object,
  disableTypography: _propTypes["default"].bool
};
DialogTitle.defaultProps = {
  classes: {},
  disableTypography: false
};
var _default = DialogTitle;
exports["default"] = _default;