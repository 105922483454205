"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useQuery", {
  enumerable: true,
  get: function get() {
    return _reactHooks.useQuery;
  }
});
Object.defineProperty(exports, "useLazyQuery", {
  enumerable: true,
  get: function get() {
    return _reactHooks.useLazyQuery;
  }
});
Object.defineProperty(exports, "useMutation", {
  enumerable: true,
  get: function get() {
    return _reactHooks.useMutation;
  }
});
Object.defineProperty(exports, "useSubscription", {
  enumerable: true,
  get: function get() {
    return _reactHooks.useSubscription;
  }
});
Object.defineProperty(exports, "useApolloClient", {
  enumerable: true,
  get: function get() {
    return _reactHooks.useApolloClient;
  }
});
Object.defineProperty(exports, "gql", {
  enumerable: true,
  get: function get() {
    return _apolloBoost.gql;
  }
});
exports["default"] = void 0;

var _GraphProvider = _interopRequireDefault(require("./GraphProvider"));

var _reactHooks = require("@apollo/react-hooks");

var _apolloBoost = require("apollo-boost");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = _GraphProvider["default"];
exports["default"] = _default;