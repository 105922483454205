"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _Paper = _interopRequireDefault(require("thieme-ui/core/Paper"));

var _TextField = _interopRequireDefault(require("thieme-ui/core/TextField"));

var _Button = _interopRequireDefault(require("thieme-ui/core/Button"));

var _Background = _interopRequireDefault(require("thieme-ui/core/Background"));

var _awsAmplify = require("aws-amplify");

var _makeStyles = require("thieme-ui/core/utils/makeStyles");

var _SnackBar = _interopRequireDefault(require("thieme-ui/core/SnackBar"));

var _Typography = require("thieme-ui/core/Typography");

var _awsExports = _interopRequireWildcard(require("../aws-exports"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var language = _awsExports.languages.de;
var useStyles = (0, _makeStyles.makeStyles)(function (theme) {
  return {
    signIn: {
      zIndex: 1,
      position: 'relative',
      margin: '160px auto',
      textAlign: 'center',
      maxWidth: '395px',
      '& img.logo': {
        maxWidth: '100%',
        marginBottom: '20px'
      }
    },
    orSeparator: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      '&:after': {
        content: "''",
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        borderBottom: '1px dashed #aaa',
        zIndex: 0
      }
    },
    or: {
      background: theme.palette.background.paper,
      zIndex: 1,
      padding: '10px'
    },
    microsoftLogo: {
      height: '24px',
      marginRight: '10px'
    },
    logo: {
      width: '90%',
      marginBottom: 20
    }
  };
});

var SignIn = function SignIn() {
  var _useState = (0, _react.useState)(''),
      _useState2 = _slicedToArray(_useState, 2),
      error = _useState2[0],
      setError = _useState2[1];

  var _useState3 = (0, _react.useState)(''),
      _useState4 = _slicedToArray(_useState3, 2),
      username = _useState4[0],
      setUsername = _useState4[1];

  var _useState5 = (0, _react.useState)(''),
      _useState6 = _slicedToArray(_useState5, 2),
      password = _useState6[0],
      setPassword = _useState6[1];

  var _useState7 = (0, _react.useState)(false),
      _useState8 = _slicedToArray(_useState7, 2),
      open = _useState8[0],
      setOpen = _useState8[1];

  var theme = (0, _makeStyles.useTheme)();
  var classes = useStyles();

  var onSubmitHandler = function onSubmitHandler(e) {
    e.preventDefault();
    setError('');

    _awsAmplify.Auth.signIn(username, password).then(Function.prototype)["catch"](function () {
      setError('Incorrect username or password');
      setOpen(true);
    });
  };

  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_Background["default"], null), _react["default"].createElement("div", {
    className: classes.signIn
  }, _react["default"].createElement(_Paper["default"], {
    elevation: 15,
    padding: true
  }, _react["default"].createElement("p", null, _react["default"].createElement("img", {
    className: classes.logo,
    src: theme.palette.type === 'dark' ? 'https://pimage.sport-thieme.de/generic-png/siteLogo-white-noSubline' : 'https://pimage.sport-thieme.de/generic-png/siteLogo-noSubline',
    alt: "Sport-Thieme logo"
  })), _react["default"].createElement(_Button["default"], {
    variant: "outlined",
    fullWidth: true,
    onClick: function onClick() {
      return _awsAmplify.Auth.federatedSignIn({
        provider: _awsExports["default"].Custom.singleSignOnProviderId
      });
    }
  }, _react["default"].createElement("img", {
    src: "https://pimage.sport-thieme.de/icon32/microsoft",
    className: classes.microsoftLogo,
    alt: "Microsoft Logo"
  }), language.signInWithOffice), _react["default"].createElement("div", {
    className: classes.orSeparator
  }, _react["default"].createElement(_Typography.P, {
    className: classes.or
  }, language.or)), _react["default"].createElement("form", {
    onSubmit: onSubmitHandler
  }, error && _react["default"].createElement(_SnackBar["default"], {
    message: error,
    variant: "error",
    open: open,
    handleClose: function handleClose() {
      return setOpen(false);
    }
  }), _react["default"].createElement(_TextField["default"], {
    required: true,
    value: username,
    onChange: function onChange(e) {
      return setUsername(e.target.value);
    },
    type: "email",
    label: "Username"
  }), _react["default"].createElement("p", null), _react["default"].createElement(_TextField["default"], {
    required: true,
    value: password,
    onChange: function onChange(e) {
      return setPassword(e.target.value);
    },
    type: "password",
    label: "Password"
  }), _react["default"].createElement("p", null, _react["default"].createElement(_Button["default"], {
    fullWidth: true,
    type: "submit"
  }, language.signIn))))));
};

var _default = SignIn;
exports["default"] = _default;