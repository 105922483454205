"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styles = require("@material-ui/core/styles");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var useStyles = (0, _styles.makeStyles)({
  '@keyframes background': {
    '0%': {
      'background-position': '30% 0%'
    },
    '50%': {
      'background-position': '71% 100%'
    },
    '100%': {
      'background-position': '30% 0%'
    }
  },
  background: {
    background: 'linear-gradient(-45deg,#fff, #EE7752,#fff, #E73C7E,#fff, #23A6D5, #23D5AB,#fff)',
    backgroundSize: '400% 400%',
    position: 'fixed',
    animation: '$background 25s ease infinite',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  }
});

var Background = function Background() {
  var classes = useStyles();
  return _react["default"].createElement("div", {
    className: classes.background
  });
};

var _default = Background;
exports["default"] = _default;