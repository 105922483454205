"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.SPORT_THIEME_DOMAIN = exports.languages = void 0;
/**
 * CONSTANTS
 */

var SPORT_THIEME_DOMAIN = 'sport-thieme.io';
/**
 * CONFIGURATIONS
 */

exports.SPORT_THIEME_DOMAIN = SPORT_THIEME_DOMAIN;
var configurations = {
  production: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_LZOe5YqJH',
      userPoolWebClientId: '7jueu39gqv5e5re8u54334d1k3',
      oauth: {
        domain: 'sport-thieme-signin.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: "https://".concat(SPORT_THIEME_DOMAIN),
        redirectSignOut: "https://".concat(SPORT_THIEME_DOMAIN),
        responseType: 'code' // code, token

      },
      cookieStorage: {
        domain: ".".concat(SPORT_THIEME_DOMAIN),
        expires: 1,
        secure: true
      }
    },
    Analytics: {
      disabled: true
    },
    Custom: {
      singleSignOnProviderId: 'ST-AzureAD'
    }
  },
  development: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_LZOe5YqJH',
      userPoolWebClientId: '5r0jrvt3783j7adnk6l39qjbrv',
      oauth: {
        domain: 'sport-thieme-signin.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:3000',
        redirectSignOut: 'http://localhost:3000',
        responseType: 'code' // code, token

      },
      cookieStorage: {
        domain: 'localhost',
        secure: false
      }
    },
    Analytics: {
      disabled: true
    },
    Custom: {
      singleSignOnProviderId: 'ST-AzureAD'
    }
    /**
     * LANGUAGES
     */

  }
};
var DE = {
  singleSignOn: 'Single Sign On',
  signIn: 'Anmelden',
  signInWithOffice: 'Anmelden mit Office365',
  signInWithUsername: 'Anmelden mit Username',
  or: 'oder'
};
var languages = {
  de: DE
  /**
   * EXPORTS
   */

};
exports.languages = languages;
var awsExports = configurations[process.env.NODE_ENV || 'development'];
var _default = awsExports;
exports["default"] = _default;