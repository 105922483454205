"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Inherit = exports.SrOnly = exports.Overline = exports.ButtonText = exports.Caption = exports.Body2 = exports.Body1 = exports.P = exports.Subtitle2 = exports.Subtitle1 = exports.H6 = exports.H5 = exports.H4 = exports.H3 = exports.H2 = exports.H1 = void 0;

var _Typography = _interopRequireDefault(require("./Typography"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var H1 = _Typography["default"].H1,
    H2 = _Typography["default"].H2,
    H3 = _Typography["default"].H3,
    H4 = _Typography["default"].H4,
    H5 = _Typography["default"].H5,
    H6 = _Typography["default"].H6,
    Subtitle1 = _Typography["default"].Subtitle1,
    Subtitle2 = _Typography["default"].Subtitle2,
    Body1 = _Typography["default"].Body1,
    Body2 = _Typography["default"].Body2,
    Caption = _Typography["default"].Caption,
    ButtonText = _Typography["default"].Button,
    Overline = _Typography["default"].Overline,
    SrOnly = _Typography["default"].SrOnly,
    Inherit = _Typography["default"].Inherit;
exports.Inherit = Inherit;
exports.SrOnly = SrOnly;
exports.Overline = Overline;
exports.ButtonText = ButtonText;
exports.Caption = Caption;
exports.Body2 = Body2;
exports.Body1 = Body1;
exports.Subtitle2 = Subtitle2;
exports.Subtitle1 = Subtitle1;
exports.H6 = H6;
exports.H5 = H5;
exports.H4 = H4;
exports.H3 = H3;
exports.H2 = H2;
exports.H1 = H1;
var P = Body1;
exports.P = P;