"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(elements) {
  return elements[Math.floor(Math.random() * elements.length)];
};

exports["default"] = _default;