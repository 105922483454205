"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _SimpleDialog["default"];
  }
});
Object.defineProperty(exports, "SimpleDialogAction", {
  enumerable: true,
  get: function get() {
    return _SimpleDialogAction["default"];
  }
});

var _SimpleDialog = _interopRequireDefault(require("./SimpleDialog"));

var _SimpleDialogAction = _interopRequireDefault(require("./SimpleDialogAction"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}