"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DialogActions = _interopRequireDefault(require("@material-ui/core/DialogActions"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DialogActions = function DialogActions(_ref) {
  var children = _ref.children,
      classes = _ref.classes,
      disableSpacing = _ref.disableSpacing;
  return _react["default"].createElement(_DialogActions["default"], {
    classes: classes,
    disableSpacing: disableSpacing
  }, children);
};

DialogActions.propTypes = {
  children: _propTypes["default"].node.isRequired,
  classes: _propTypes["default"].object,
  disableSpacing: _propTypes["default"].bool
};
DialogActions.defaultProps = {
  classes: {},
  disableSpacing: false
};
var _default = DialogActions;
exports["default"] = _default;