"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Dialog = _interopRequireDefault(require("@material-ui/core/Dialog"));

var _Transition = _interopRequireDefault(require("../Transition"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Dialog = function Dialog(_ref) {
  var open = _ref.open,
      children = _ref.children,
      classes = _ref.classes,
      disableBackdropClick = _ref.disableBackdropClick,
      disableEscapeKeyDown = _ref.disableEscapeKeyDown,
      fullScreen = _ref.fullScreen,
      fullWidth = _ref.fullWidth,
      maxWidth = _ref.maxWidth,
      onBackdropClick = _ref.onBackdropClick,
      onClose = _ref.onClose,
      onEnter = _ref.onEnter,
      onEntered = _ref.onEntered,
      onEscapeKeyDown = _ref.onEscapeKeyDown,
      onExit = _ref.onExit,
      onExited = _ref.onExited,
      onExiting = _ref.onExiting,
      PaperProps = _ref.PaperProps,
      scoll = _ref.scoll,
      TransitionComponent = _ref.TransitionComponent,
      transitionDuration = _ref.transitionDuration,
      TransitionProps = _ref.TransitionProps;
  return _react["default"].createElement(_Dialog["default"], {
    classes: classes,
    disableBackdropClick: disableBackdropClick,
    disableEscapeKeyDown: disableEscapeKeyDown,
    fullScreen: fullScreen,
    fullWidth: fullWidth,
    maxWidth: maxWidth,
    onBackdropClick: onBackdropClick,
    onClose: onClose,
    onEnter: onEnter,
    onEntered: onEntered,
    onEscapeKeyDown: onEscapeKeyDown,
    onExit: onExit,
    onExited: onExited,
    onExiting: onExiting,
    open: open,
    PaperProps: PaperProps,
    scoll: scoll,
    TransitionComponent: TransitionComponent,
    transitionDuration: transitionDuration,
    TransitionProps: TransitionProps
  }, children);
};
/**
 * TODO: Add real PROPTYPES here
 */


Dialog.propTypes = {
  /**
   * Dialog children, usually the included sub-components.
   */
  children: _propTypes["default"].node.isRequired,

  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api)
   */
  classes: _propTypes["default"].object,

  /**
   * Override or extend the styles applied to the component. See CSS API below for more  details.
   */
  disableBackdropClick: _propTypes["default"].bool,
  disableEscapeKeyDown: _propTypes["default"].bool,

  /**
   * If `true`, clicking the backdrop will not fire the onClose callback.
   */
  fullScreen: _propTypes["default"].bool,

  /**
   * If `true`, the dialog stretches to maxWidth.
   */
  fullWidth: _propTypes["default"].bool,

  /**
   * Determine the max-width of the dialog.
   * The dialog width grows with the size of the screen. Set to false to disable maxWidth.
   */
  maxWidth: _propTypes["default"].oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),

  /**
   * Callback fired when the backdrop is clicked.
   */
  onBackdropClick: _propTypes["default"].func,

  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: _propTypes["default"].func,

  /**
   * Callback fired before the dialog enters.
   */
  onEnter: _propTypes["default"].func,

  /**
   * Callback fired when the dialog has entered.
   */
  onEntered: _propTypes["default"].func,

  /**
   * Callback fired when the escape key is pressed,
   * disableKeyboard is false and the modal is in focus.
   */
  onEscapeKeyDown: _propTypes["default"].func,

  /**
   * Callback fired before the dialog exits.
   */
  onExit: _propTypes["default"].func,

  /**
   * Callback fired when the dialog has exited.
   */
  onExited: _propTypes["default"].func,

  /**
   * Callback fired when the dialog is exiting.
   */
  onExiting: _propTypes["default"].func,

  /**
   * If `true`, the Dialog is open.
   */
  open: _propTypes["default"].bool.isRequired,

  /**
   * Props applied to the Paper element.
   */
  PaperProps: _propTypes["default"].object,

  /**
   * Determine the container for scrolling the dialog.
   */
  scoll: _propTypes["default"].oneOf(['body', 'paper']),

  /**
   * The component used for the transition.
   */
  TransitionComponent: _propTypes["default"].elementType,

  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   */
  transitionDuration: _propTypes["default"].oneOfType([_propTypes["default"].number, _propTypes["default"].shape({
    enter: _propTypes["default"].number,
    exit: _propTypes["default"].number
  })]),

  /**
   * Props applied to the Transition element.
   */
  TransitionProps: _propTypes["default"].object
};
Dialog.defaultProps = {
  classes: {},
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  fullScreen: false,
  fullWidth: false,
  maxWidth: 'sm',
  onBackdropClick: Function.prototype,
  onClose: Function.prototype,
  onEnter: Function.prototype,
  onEntered: Function.prototype,
  onEscapeKeyDown: Function.prototype,
  onExit: Function.prototype,
  onExited: Function.prototype,
  onExiting: Function.prototype,
  PaperProps: {},
  scoll: 'paper',
  TransitionComponent: _Transition["default"],
  transitionDuration: 200,
  TransitionProps: {
    direction: 'up'
  }
};
var _default = Dialog;
exports["default"] = _default;